export function convertDateToMonthYear(date: string | null) {
  if (date) {
    const dateToConvert = new Date(date)
    const month = dateToConvert.toLocaleString('default', { month: 'long' })
    let capitalizedMonth = month[0].toUpperCase() + month.slice(1).toLowerCase()
    if (capitalizedMonth.length > 5) {
      capitalizedMonth = capitalizedMonth.substring(0, 3) + '.'
    }
    const year = dateToConvert.getFullYear()
    return `${capitalizedMonth} ${year}`
  } else {
    return 'En cours'
  }
}

export function extractYearFromDate(date: string | null) {
  if (date) {
    const dateToConvert = new Date(date)
    const year = dateToConvert.getFullYear()
    return year
  } else {
    return 3000
  }
}
