<script setup lang="ts">
import type { Training } from '../schemas/training.ts'

import { convertDateToMonthYear } from '../helpers/dates'

type Props = { training: Training }
const props = defineProps<Props>()

const words = props.training.learned_skills.split(' ')
</script>

<template>
  <transition name="card" appear>
    <div id="card" class="flex flex-col items-center bg-lines bg-opacity-10 w-full md:w-2/5 h-5/6 shadow-lg rounded-lg">
      <div class="h-2/6 mt-2 text-center">
        <p class="mt-2 txt-regular text-lg lg:text-xl text-highlight">{{ training.title }}</p>
        <p class="txt-light text-sm lg:text-lg">{{ training.organism }} - {{ training.site }}</p>
      </div>
      <div class="flex flex-col lg:flex-row items-center h-4/6 w-full">
        <div class="txt-bold text-xs md:ml-2 xl:text-sm w-full lg:w-1/4 text-center">
          {{ convertDateToMonthYear(training.delivery_date) }}
        </div>
        <div class="flex flex-wrap justify-center text-sm my-4 w-full lg:w-3/4">
          <div v-for="(word, index) in words" :key="index" class="px-2 text-param">
            {{ word }}
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<style>
.card-enter-active {
  transition: all 0.8s ease-out;
}

.card-leave-active {
  transition: all 0.8s ease-out;
}

.card-enter-from,
.card-leave-to {
  transform: rotateX(90deg);
  opacity: 0;
}
</style>
