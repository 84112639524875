<script setup lang="ts"></script>

<template>
  <div class="hidden md:flex flex-col w-1/6 h-full">
    <div class="flex border-b border-lines border-opacity-50 w-full h-1/2">
      <div class="w-1/2"></div>
    </div>
    <div class="flex w-full h-1/2">
      <div class="w-1/2"></div>
    </div>
  </div>
</template>
