<script setup lang="ts">
import type { Training } from '../schemas/training.ts'

type Props = { training: Training; alignment: string }

const props = defineProps<Props>()

const logo = props.training.logo ? props.training.logo : ''
</script>

<template>
  <transition name="logo" appear>
    <div class="w-2/5 h-32 hidden md:flex" :class="alignment">
      <img :src="logo" alt="logo" class="shadow-lg rounded-lg" />
    </div>
  </transition>
</template>

<style>
.logo-enter-active {
  transition: all 1s ease-out;
}

.logo-leave-active {
  transition: all 1s ease-out;
}

.logo-enter-from,
.logo-leave-to {
  transform: rotateX(90deg);
  opacity: 0;
}
</style>
