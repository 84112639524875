import { z } from 'zod'

export const trainingSchema = z.object({
  id: z.number(),
  title: z.string(),
  organism: z.string(),
  delivery_date: z.string().date().nullable(),
  site: z.string(),
  learned_skills: z.string(),
  logo: z.string().url({ message: 'Invalid url' }).nullable(),
})

export type Training = z.infer<typeof trainingSchema>
