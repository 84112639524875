<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import type { Training } from '../schemas/training'
import type { Skill } from '../schemas/skills'
import type { Career } from '../schemas/career'
import type { Profile } from '../schemas/profile'
import { fetchTrainings } from '../services/training'
import { fetchCareers } from '../services/career'
import { fetchSkills } from '../services/skills'
import { fetchProfile } from '../services/profile'
import { convertDateToMonthYear, extractYearFromDate } from '../helpers/dates'
import { marked } from 'marked'

const profile = ref<Profile>()
const trainings = ref<Array<Training>>()
const skills = ref<Array<Skill>>()
const experiences = ref<Array<Career>>()
const softSkills = [
  'Adaptabilité',
  'Opiniâtreté',
  'Autonomie',
  'Rigueur et organisation',
  'Rédaction de documentation (Confluence)',
  'Suivi et rédaction de process',
  'Méthode Agile (Jira)',
]
const languages = [
  { language: 'Français', level: 100, dx: 0, dy: 0 },
  { language: 'Anglais', level: 70, dx: 50, dy: -10 },
  { language: 'Espagnol', level: 35, dx: 35, dy: 30 },
]

const hobbies = [
  'hobby_rando.png',
  'hobby_cine.png',
  'hobby_jardin.png',
  'hobby_taichi.png',
  'hobby_livre.png',
  'hobby_ski.png',
  'hobby_tricot.png',
]

function getImageUrl(imagePath: string) {
  return new URL(`../assets/${imagePath}`, import.meta.url).href
}

async function printPage() {
  window.print()
}

function birthDate(date: string | undefined) {
  if (date) {
    const newDate = new Date(date)
    return newDate.toLocaleString('default', { day: 'numeric', month: 'long', year: 'numeric' })
  }
}

const filteredSkills = computed(() => {
  if (skills.value) {
    const newskills = skills.value.slice()
    newskills.sort((a, b) => (a.category > b.category ? -1 : b.category > a.category ? 1 : 0))
    return newskills.filter((skill) => skill.on_cv)
  }
  return skills.value
})

onMounted(async () => {
  const dataTrainings = await fetchTrainings()
  trainings.value = dataTrainings.results

  const dataCareers = await fetchCareers()
  experiences.value = dataCareers.results

  const dataSkills = await fetchSkills()
  skills.value = dataSkills.results

  profile.value = await fetchProfile()
})
</script>

<template>
  <div class="flex flex-col items-center print:w-full w-11/12 max-w-[1600px]">
    <div class="flex justify-between txt-regular mb-4 lg:my-10 lg:ml-10 print:hidden w-full">
      <div class="text-base md:text-xl lg:text-2xl">Mon CV</div>
      <button
        class="mr-10 text-xs md:text-sm lg:text-md px-2 bg-param rounded-xl shadow shadow-current"
        @click="printPage"
      >
        Exporter au format PDF
      </button>
    </div>

    <div class="sm:hidden">
      <img src="../assets/CV.png" alt="CV" />
    </div>

    <div
      class="print px-6 py-6 print:px-2 print:py-0 bg-white text-neutral-800 print:w-full 2xl:w-8/12 xl:w-9/12 lg:w-11/12 hidden sm:block mb-10 text-xs md:text-sm lg:text-md"
    >
      <div class="flex w-full justify-between">
        <div class="flex flex-col w-full">
          <div class="flex w-full justify-between">
            <h1 class="text-2xl text-sky-900 underline underline-offset-4">
              {{ profile?.first_name.toUpperCase() }} {{ profile?.last_name.toUpperCase() }}
            </h1>
            <div class="mx-4">
              Née le {{ birthDate(profile?.birth_date) }}
              <br />
              à Tarbes(65)
            </div>
          </div>
          <p>Développeuse Python Django Javascript React Vue</p>
          <div class="bg-sky-600 w-full text-neutral-200 mt-4 px-3 py-2 text-xs">
            A mi chemin de ma vie j’ai eu envie d’approfondir une de mes passions de toujours: coder. Pour cette
            nouvelle plongée dans les languages de programmation j’ai choisi Python. J’ai fait entre autre connaissance
            avec Django: Un véritable coup de coeur ! J'ai grandi chez EcoCO2 au sein d’une équipe formidable. J’y ai
            découvert Vue.JS, un deuxième coup de coeur.
            <br />
            Aujourd'hui j'aspire à renforcer mes compétences front-end, en concrétisant un parcours certifiant, afin de
            pouvoir accéder à des postes fullstack.
          </div>
        </div>
        <div class="bg-sky-600">
          <img src="../assets/photo.png" alt="photo" class="w-44" />
        </div>
      </div>

      <div class="flex w-full my-4">
        <div class="flex flex-col w-1/2">
          <h2 class="text-sky-900 text-xl">COMPETENCES</h2>
          <hr class="bg-sky-900 h-1 w-11/12" />
          <h3 class="txt-bold my-2">SoftSkill</h3>
          <div v-for="(softSkill, index) in softSkills" :key="index" class="ml-4">{{ softSkill }}</div>

          <h3 class="txt-bold my-2">Compétences techniques</h3>
          <div v-for="skill in filteredSkills" :key="skill.id" class="ml-4 flex justify-between">
            <div class="w-1/2">{{ skill.title }}</div>
            <progress
              id="bar"
              class="h-3 mr-10 skill-bar border border-neutral-300"
              :value="skill.competence_level"
              :max="20"
            ></progress>
          </div>

          <h3 class="txt-bold my-2">Langues</h3>
          <svg width="300" height="100" viewBox="0 0 300 100" xmlns="http://www.w3.org/2000/svg">
            <circle
              v-for="(language, index) in languages"
              :key="index"
              :r="language.level / 2"
              :cx="100 + language.dx"
              :cy="50 + language.dy"
              fill="#0284c7"
              stroke="white"
            />
            <text
              v-for="(language, index) in languages"
              :key="index"
              :x="30 + language.dx * 2.2"
              :y="55 + language.dy + 1.5"
              fill="#262626"
            >
              {{ language.language }}
            </text>
          </svg>

          <h3 class="txt-bold">Loisirs</h3>
          <div class="flex gap justify-center flex-wrap gap-2 mr-8 mb-4">
            <div v-for="(hobby, index) in hobbies" :key="index" class="w-14">
              <img :src="getImageUrl(hobby)" :alt="'photo ' + hobby" />
            </div>
          </div>

          <div class="flex flex-col justify-between txt-light">
            <p>{{ profile?.github_link }}</p>
            <p>{{ profile?.gitlab_link }}</p>
            <p>{{ profile?.linkedin_link }}</p>
          </div>
        </div>

        <div class="flex flex-col w-1/2">
          <h2 class="text-sky-900 text-xl">EXPERIENCES</h2>
          <hr class="bg-sky-900 h-1 w-11/12 mb-2" />
          <div v-for="experience in experiences" :key="experience.id" class="mb-2">
            <h3 class="txt-bold">
              {{ convertDateToMonthYear(experience.start_date) }} - {{ convertDateToMonthYear(experience.end_date) }}
            </h3>
            <p class="text-sky-600">{{ experience.job }}</p>
            <p class="txt-light">{{ experience.firm }} - {{ experience.type }} - {{ experience.site }}</p>
            <p class="text-xs" v-html="marked.parse(experience.details ?? '')"></p>
          </div>

          <h2 class="text-sky-900 text-xl mt-4">FORMATIONS</h2>
          <hr class="bg-sky-900 h-1 w-11/12 mb-2" />
          <div v-for="training in trainings" :key="training.id" class="mb-2">
            <h3 class="txt-bold">{{ convertDateToMonthYear(training.delivery_date) }}</h3>
            <p class="text-sky-600">{{ training.title }}</p>
            <p>{{ training.organism }} - {{ training.site }}</p>
            <p class="txt-light text-xs" v-if="extractYearFromDate(training?.delivery_date) > 2015">
              {{ training.learned_skills }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.skill-bar::-webkit-progress-value {
  background-color: #0284c7;
  border-radius: 50px;
}
.skill-bar::-moz-progress-bar {
  background-color: #0284c7;
  border-radius: 50px;
}
progress::-webkit-progress-bar {
  background-color: transparent;
  border-radius: 50px;
}
progress {
  background-color: transparent;
  border-radius: 50px;
}
</style>
